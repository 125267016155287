import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {

    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/admin/dashboard'),
            
            new AppMenuItem('Membership', '', 'flaticon-users', '', [
                new AppMenuItem('Members', 'Pages.Members', 'flaticon-users', '/app/main/members'),
                new AppMenuItem('Groups/Employer', 'Pages.Groups', 'flaticon-users', '/app/main/groups'),
                new AppMenuItem('Member Dump', 'Pages.Members', 'flaticon-users', '/app/main/member-dump'),
                new AppMenuItem('Directors', 'Pages.Directors', 'flaticon-users', '/app/main/directors'),
                //new AppMenuItem('Corporate', 'Pages.Institutions', 'flaticon-users', '/app/main/institutions'),
            ]),

            new AppMenuItem('Customer Service', '', 'flaticon-app', '', [
                //new AppMenuItem('Customer Service', 'Pages.Groups', 'flaticon-app', '/app/main/customer-service'),
                new AppMenuItem('Service', 'Pages.Groups', 'flaticon-app', '/app/main/customer-service/cust-service'),
                new AppMenuItem('Statement', 'Pages.Groups', 'flaticon-app', '/app/main/customer-service/statement'),
            ]),
            //Pages.Shemes /app/main/schemes
            new AppMenuItem('Schemes', '', 'flaticon-suitcase', '', [
                new AppMenuItem('Scheme', 'Pages.Shemes', 'flaticon-users', '/app/main/schemes'),
                new AppMenuItem('Beneficiaries', 'Pages.Beneficiaries', 'flaticon-users', '/app/main/schemes/beneficiaries'),
                //new AppMenuItem('Transaction Enquiry', 'Pages.Beneficiaries', 'flaticon-users', '/app/main/schemes/transaction-enquiry'),
            ]),

            new AppMenuItem('Payments', '', 'flaticon-suitcase', '', [
                new AppMenuItem('Member Payment', 'Pages.Payments', 'flaticon-medical', '/app/main/payments'),
                new AppMenuItem('Group Payment', 'Pages.Payments', 'flaticon-medical', '/app/main/payments/group'),
                new AppMenuItem('Payment Dump', 'Pages.Payments', 'flaticon-medical', '/app/main/payments/data-dump')
            ]),
            
            new AppMenuItem('Financials', '', 'flaticon-suitcase', '', [
                //FINANCIAL REPORTS AND OTHERS HERE
                new AppMenuItem('Journal Posting', 'Pages.Financials.JournalPosting', 'flaticon-medical', '/app/main/financials/journal-posting'),
                //new AppMenuItem('BatchPosting', 'Pages.Groups', 'flaticon-medical', '/app/main/financials/batch-posting'),
                new AppMenuItem('Nominal Enquiry', 'Pages.Financials.NominalEnquiry', 'flaticon-medical', '/app/main/financials/gl-enquiry'),
                new AppMenuItem('Cashbook', 'Pages.Financials.CashbookSummary', 'flaticon-medical', '/app/main/financials/cash-book'),
                new AppMenuItem('Approvals', 'Pages.Approvals', 'flaticon-medical', '/app/main/financials/approval'),
                new AppMenuItem('Transaction Enquiry', 'Pages.Financials.TransactionEnquiry', 'flaticon-medical', '/app/main/financials/financial-transaction-enquiry'),
                new AppMenuItem('Cashbooks', 'Pages.Financials.FinancialReport', 'flaticon-medical', '/app/main/financials/financial-report'),
                // new AppMenuItem('Trial Balance', '', 'flaticon-suitcase', '', [
                //     new AppMenuItem('Trial Balance Scheme','Pages.Financials.TransactionEnquiry', 'flaticon-medical', '/app/main/financials/trial-balance/trial-balance-scheme'),
                //     new AppMenuItem('Schemes','Pages.Financials.TransactionEnquiry', 'flaticon-medical', '/app/main/financials/trial-balance/trial-balance-consolidated'),
                // ]),
                // new AppMenuItem('Trial Balance Scheme','Pages.Financials.TransactionEnquiry', 'flaticon-medical', '/app/main/financials/trial-balance/trial-balance-scheme'),
                // new AppMenuItem('Schemes','Pages.Financials.TransactionEnquiry', 'flaticon-medical', '/app/main/financials/trial-balance/trial-balance-consolidated'),
                
                new AppMenuItem('Trial Balance','Pages.Financials.TransactionEnquiry', 'flaticon-medical', '/app/main/financials/scheme-trial-balance'),
                new AppMenuItem('Schemes','', 'flaticon-medical', '',[
                    new AppMenuItem('Schemes','Pages.Financials.TransactionEnquiry', 'flaticon-medical', '/app/main/financials/consolidated-trial-balance'),
                    new AppMenuItem('Other Reports','Pages.Financials.TransactionEnquiry', 'flaticon-medical', '/app/main/financials/scheme-report')

                ]),
                
                new AppMenuItem('Data Dump', 'Pages.Financials.TransactionEnquiry', 'flaticon-medical', '/app/main/financials/data-dump'),
            ]),

            // new AppMenuItem('Channels', 'Pages.Groups', 'flaticon-suitcase', '/app/main/channels'),
            new AppMenuItem('Other Channels', '', 'flaticon-app', '', [
                new AppMenuItem('Payment Log', 'Pages.OtherChannels', 'flaticon-app', '/app/main/other-channels/channels-payment-log'),
                // new AppMenuItem('Online Registration', 'Pages.OtherChannels', 'flaticon-app', '/app/main/other-channels/channels-new-members'),
            ]),

            new AppMenuItem('Fund Management', '', 'flaticon-suitcase', '', [
                //FINANCIAL REPORTS AND OTHERS HERE
                new AppMenuItem('Unitization', 'Pages.UnitPrices', 'flaticon-medical', '/app/main/fund-manager/unitization'),
                new AppMenuItem('Investment', 'Pages.Investments', 'flaticon-medical', '/app/main/fund-manager/investment'),
                new AppMenuItem('Fund Manager', 'Pages.Investments.Payments', 'flaticon-medical', '/app/main/fund-manager/fund-manager-payments'),
                //new AppMenuItem('Transaction Enquiry', 'Pages.Groups', 'flaticon-medical', '/app/main/fund-manager/fund-transaction-enquiry'),
                new AppMenuItem('Unit Price Register', 'Pages.UnitPrices', 'flaticon-medical', '/app/main/fund-manager/unit-register'),
                new AppMenuItem('Investment Dump', 'Pages.Investments', 'flaticon-medical', '/app/main/fund-manager/investment-dump'),
            ]),

            new AppMenuItem('Setup', '', 'flaticon-interface-8', '', [
                new AppMenuItem('Institutions', 'Pages.Institutions', 'flaticon-home', '/app/main/setup/institutions'),
                new AppMenuItem('Genders', 'Pages.Genders', 'flaticon-users', '/app/main/setup/genders'),
                new AppMenuItem('Channel Users', 'Pages.ChannelUsers', 'flaticon-map', '/app/main/setup/channel-users'),
                new AppMenuItem('Countries', 'Pages.Countries', 'flaticon-map', '/app/main/setup/countries'),
                new AppMenuItem('Territories', 'Pages.Countries', 'flaticon-map', '/app/main/setup/territories'),
                new AppMenuItem('Sales Agent', 'Pages.Countries', 'flaticon-map', '/app/main/setup/sales-agent'),
                new AppMenuItem('Regions', 'Pages.Regions', 'flaticon-map', '/app/main/setup/regions'),
                new AppMenuItem('Sectors', 'Pages.Sectors', 'flaticon-map', '/app/main/setup/sectors'),
                new AppMenuItem('Occupations', 'Pages.Occupations', 'flaticon-folder-1', '/app/main/setup/occupations'),
                new AppMenuItem('Verifications', 'Pages.Verifications', 'flaticon-lock', '/app/main/setup/verifications'),
                new AppMenuItem('Sequences', 'Pages.Sequences', 'flaticon-settings', '/app/main/setup/sequences'),
                new AppMenuItem('Relation Officers', 'Pages.RelationOfficers', 'flaticon-users', '/app/main/setup/relation-officers'),
                new AppMenuItem('Agents', 'Pages.RelationOfficers', 'flaticon-users', '/app/main/setup/agents'),
                new AppMenuItem('Sessions', 'Pages.Sessions', 'flaticon-time', '/app/main/setup/sessions'),
                new AppMenuItem('Nominal Codes', 'Pages.NominalCodes', 'flaticon-folder-1', '/app/main/setup/nominal-codes'),
                new AppMenuItem('Tiers/Charges', 'Pages.Banktierses', 'flaticon-suitcase', '/app/main/setup/banktierses'),
                new AppMenuItem('Api Setups', 'Pages.ApiSetups', 'flaticon-map', '/app/main/setup/api-setups'),
                new AppMenuItem('Product Types', 'Pages.ProductTypes', 'flaticon-lock', '/app/main/setup/product-types'),
                new AppMenuItem('Fiscal Year', 'Pages.ProductTypes', 'flaticon-lock', '/app/main/setup/fiscal-year'),
                new AppMenuItem('System Keies', 'Pages.ProductTypes', 'flaticon-lock', '/app/main/setup/system-keies'),
                //new AppMenuItem('Migration', 'Pages.Institutions', 'flaticon-lock', '/app/main/setup/migration'),
                new AppMenuItem('Migration', '', 'flaticon-home', '', [
                    new AppMenuItem('Member', 'Pages.Groups', 'flaticon-medical', '/app/main/setup/migration/member-migration'),
                    new AppMenuItem('Group/Employer', 'Pages.Groups', 'flaticon-medical', '/app/main/setup/migration/group-migration'),
                    new AppMenuItem('Investment', 'Pages.Groups', 'flaticon-medical', '/app/main/setup/migration/investment-migration'),
                    new AppMenuItem('Nominal Code', 'Pages.Groups', 'flaticon-medical', '/app/main/setup/migration/nominal-code-migration'),
                    new AppMenuItem('Relation Officer', 'Pages.Groups', 'flaticon-medical', '/app/main/setup/migration/relation-officer-migration'),
                    new AppMenuItem('Scheme', 'Pages.Groups', 'flaticon-medical', '/app/main/setup/migration/scheme-migration'),
                    new AppMenuItem('Beneficiary', 'Pages.Groups', 'flaticon-medical', '/app/main/setup/migration/beneficiary-migration'),
                    new AppMenuItem('Occupation', 'Pages.Groups', 'flaticon-medical', '/app/main/setup/migration/occupation-migration'),
                    new AppMenuItem('Country', 'Pages.Groups', 'flaticon-medical', '/app/main/setup/migration/country-migration'),
                ]),
                // new AppMenuItem('AccountTypes', 'Pages.AccountTypes', 'flaticon-lock', '/app/main/account-types'),
            ]),        
            
            new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [
                new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
                new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'), 
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
            ]),
            new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null || subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach(menuItem => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
