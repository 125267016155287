import { AbpHttpInterceptor, RefreshTokenService } from '@abp/abpHttpInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ImportService } from './import.service'

@NgModule({
    providers: [
        ApiServiceProxies.ProductTypesServiceProxy,        
        ApiServiceProxies.OtherChannelsServiceProxy,        
        ApiServiceProxies.PaymentsServiceProxy,        
        ApiServiceProxies.InvestmentsServiceProxy,        
        ApiServiceProxies.BatchItemsServiceProxy,        
        ApiServiceProxies.ImageGalleriesServiceProxy,        
        ApiServiceProxies.BatchHeadersServiceProxy,        
        ApiServiceProxies.BeneficiariesServiceProxy,        
        ApiServiceProxies.ShemesServiceProxy,        
        ApiServiceProxies.MembersServiceProxy,        
        ApiServiceProxies.GroupsServiceProxy,      
        ApiServiceProxies.RelationOfficersServiceProxy,        
        ApiServiceProxies.ApiSetupsServiceProxy,        
        ApiServiceProxies.BanktiersesServiceProxy,        
        ApiServiceProxies.NominalCodesServiceProxy,
        ApiServiceProxies.TerritoriesServiceProxy, 
        ApiServiceProxies.SalesAgentsServiceProxy, 
        ApiServiceProxies.UnitPricesServiceProxy,   
        ApiServiceProxies.ApprovalsServiceProxy,  
        ApiServiceProxies.RegionsServiceProxy,     
        ApiServiceProxies.SectorsServiceProxy,     
        ApiServiceProxies.DirectorsServiceProxy,     
        ApiServiceProxies.SessionsServiceProxy,        
        ApiServiceProxies.SequencesServiceProxy,        
        ApiServiceProxies.VerificationsServiceProxy,        
        ApiServiceProxies.OccupationsServiceProxy, 
        ApiServiceProxies.ChannelUsersServiceProxy,        
        ApiServiceProxies.CountriesServiceProxy,        
        // ApiServiceProxies.GendersServiceProxy,        
        ApiServiceProxies.InstitutionsServiceProxy,        
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.CountriesServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.FiscalYearsServiceProxy,
        ApiServiceProxies.SystemKeiesServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy, ImportService,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
