import { Component, Injector, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SessionDto, SessionServiceProxy, SessionsServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
    templateUrl: './footer.component.html',
    selector: 'footer-bar'
})
export class FooterComponent extends AppComponentBase implements OnInit {

    sessionDate: any;
    @Input() useBottomDiv = true;

    //sessionDate: SessionDto = new SessionDto();

    constructor(injector: Injector,
        private _sessionsServiceProxy: SessionsServiceProxy,
        //private _activatedRoute: ActivatedRoute
    ) {super(injector); }

    ngOnInit(): void {
        this.sessionDate = this.appSession.application.releaseDate.format('YYYYMMDD');
        this.show();
    }

    show(): void {
        this._sessionsServiceProxy.getSessionDate().subscribe(result => {					
          this.sessionDate = result; 
        });
    }

}
